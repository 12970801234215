/*
  forms.js
  Handles form validation and submission
*/
import $ from 'jquery';

function validateField(field) {
  if (field.val() !== '') {
    field.parent().find('label span.error').removeClass('shown');
  } else {
    field.parent().find('label span.error').addClass('shown');
  }
}

function validateForm() {
  validateField($('#contact-name'));
  validateField($('#contact-email'));
  validateField($('#contact-message'));
  return !($('#contact_form label span.error.shown').length);
}

function submitForm() {
  const $contactForm = $('#contact_form');
  const $overlay = $('.contact_form_overlay');
  $.ajax({
    url: 'https://formspree.io/bobby@bobbyzeleny.com',
    method: 'POST',
    data: $contactForm.serialize(),
    dataType: 'json',
    beforeSend: () => {
      $overlay.addClass('show');
      $overlay.html('<div class="contact-form-message contact-form-sending">Sending message…</div>');
    },
    success: () => {
      $overlay.html('<div class="contact-form-message contact-form-success">Message sent!</div>');
    },
    error: () => {
      $overlay.html('<div class="contact-form-message contact-form-error">Ops, there was an error.</div>');
    },
  });
}

export default function () {
  $('.form-element-submit .btn').on('click', () => {
    if (validateForm()) {
      submitForm();
    }
  });
}
