import 'plyr/dist/plyr.css';
import Plyr from 'plyr';

import $ from 'jquery';

export default function () {
  let videoPlayer = {};

  $('.video_link').on('click', (event) => {
    $('.video_container').addClass('show');
    const videoSource = $(event.currentTarget).data().target;
    if (videoSource.indexOf('vimeo') !== -1) {
      $('#video').attr('data-plyr-provider', 'vimeo');
    } else {
      $('#video').attr('data-plyr-provider', 'youtube');
    }
    $('#video').attr('data-plyr-embed-id', videoSource);
    videoPlayer = new Plyr('#video');
  });

  $('.close_video').on('click', () => {
    videoPlayer.pause();
    $('.video_container').removeClass('show');
    videoPlayer.destroy();
  });
}

